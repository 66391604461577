exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-best-coffee-in-newhall-js": () => import("./../../../src/pages/best-coffee-in-newhall.js" /* webpackChunkName: "component---src-pages-best-coffee-in-newhall-js" */),
  "component---src-pages-best-eggs-benedict-in-newhall-js": () => import("./../../../src/pages/best-eggs-benedict-in-newhall.js" /* webpackChunkName: "component---src-pages-best-eggs-benedict-in-newhall-js" */),
  "component---src-pages-best-pancakes-in-newhall-js": () => import("./../../../src/pages/best-pancakes-in-newhall.js" /* webpackChunkName: "component---src-pages-best-pancakes-in-newhall-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-menu-bakery-items-js": () => import("./../../../src/pages/menu/bakery-items.js" /* webpackChunkName: "component---src-pages-menu-bakery-items-js" */),
  "component---src-pages-menu-food-items-js": () => import("./../../../src/pages/menu/food-items.js" /* webpackChunkName: "component---src-pages-menu-food-items-js" */),
  "component---src-pages-menu-index-js": () => import("./../../../src/pages/menu/index.js" /* webpackChunkName: "component---src-pages-menu-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */)
}

