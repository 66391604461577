import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
// import { BsCaretRight, BsCaretLeft } from "react-icons/bs"

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Pagination = styled.ul`
  display: flex;
  text-align: center;
  background: var(--secondaryColor);
  border-radius: 10px;

  > li {
    width: 53px;
    height: 40px;
    padding-top: 12px;
    transition: all 0.5s;

    > a {
      font-weight: 600;
      color: var(--white);
      font-family: "Oswald", sans-serif;

      > svg {
        margin-top: -2px;
      }
    }
  }
`

const Links = ({ currentPage, numberOfpage }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numberOfpage
  const previousPage =
    currentPage - 1 === 1
      ? "/gallery"
      : "/gallery/" + (currentPage - 1).toString()

  const nextPage = "/gallery/" + (currentPage + 1).toString()
  return (
    <PaginationWrapper>
      <Pagination aria-label="Page navigation example">
        {!isFirst && (
          <li className="text-center" key="prev">
            <Link activeClassName="p-active" to={previousPage} rel="prev">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                size="20"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 12.796L4.519 8 10 3.204v9.592zm-.659.753l-5.48-4.796a1 1 0 010-1.506l5.48-4.796A1 1 0 0111 3.204v9.592a1 1 0 01-1.659.753z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </li>
        )}

        {Array.from({ length: numberOfpage }, (_, i) =>
          currentPage === i + 1 ? (
            <li className="text-center" key={`page-number${i + 1}`}>
              <Link
                activeClassName="p-active"
                to={`/${i === 0 ? "gallery" : "gallery/" + (i + 1)}`}
              >
                {i + 1}
              </Link>
            </li>
          ) : (
            <li className="text-center" key={`page-number${i + 1}`}>
              <Link
                activeClassName="p-active"
                to={`/${i === 0 ? "gallery" : "gallery/" + (i + 1)}`}
              >
                {i + 1}
              </Link>
            </li>
          )
        )}
        {!isLast && (
          <li className="text-center" key="next">
            <Link activeClassName="p-active" to={nextPage} rel="next">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                size="20"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 12.796L11.481 8 6 3.204v9.592zm.659.753l5.48-4.796a1 1 0 000-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 001.659.753z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </li>
        )}
      </Pagination>
    </PaginationWrapper>
  )
}

export { Links }
