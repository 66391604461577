import React from "react"
import Carousel from "react-multi-carousel"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import { MainWrapper, SectionHeader } from "components"
import { GalleryWrapper, MainContent } from "./styles"
import background from "../../images/mini-black-back.png"

export const Gallery = () => {
  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(height: 400, width: 500, placeholder: BLURRED)
          }
          name
        }
      }
    }
  `)

  //carousel setting
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <GalleryWrapper background={background} id="gallery">
      <MainContent>
        <MainWrapper>
          <SectionHeader color="var(--secondaryColor)" title="Gallery" />
          <Carousel infinite={true} responsive={responsive} showDots={false}>
            {allFile.nodes.map(item => (
              <div key={item.name} className="y-content">
                <GatsbyImage
                  imgStyle={{ borderRadius: "20px" }}
                  image={getImage(item)}
                  alt={item.name}
                />
              </div>
            ))}
          </Carousel>
        </MainWrapper>
      </MainContent>
    </GalleryWrapper>
  )
}
