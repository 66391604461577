import styled from "styled-components"

export const CardWrapper = styled.div`
  padding: ${({ padding }) => padding || "50px 25px"};
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 5%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    transform: translateY(-5px);
  }
`

export const TextAndContent = styled.div`
  > h1 {
    font-family: "Oswald", Sans-serif;
    font-size: 21px;
    margin-top: 15px;
  }
  > p {
    color: var(--secondaryColor);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 15px;
  }
`
