import React from "react"
import Carousel from "react-multi-carousel"
import { StaticImage } from "gatsby-plugin-image"

import { HowBack, RevWrap, Slider } from "./styles"
import { MainWrapper } from "components"
import background from "../../images/back.png"

export const ReviewAndInsta = () => {
  //carousel setting
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <HowBack background={background}>
      <MainWrapper>
        <RevWrap>
          <Slider>
            <Carousel showDots={false} responsive={responsive}>
              <div className="y-content">
                <div className="content">
                  <Star />
                  <h5>
                    Great food, good ambiance and engaging wait staff. Food was
                    delivered quickly, and drinks were kept fresh. I got the
                    trio crepes, which are pictured, they were a great kickoff
                    for the day.
                  </h5>
                  <h6>- Brandon Taylor</h6>
                </div>
              </div>
              <div className="y-content">
                <div className="content">
                  <Star />
                  <h5>
                    "A favorite spot for breakfast. We think the Benedicts are
                    all great and I recommend sharing the churro waffle or the
                    horchata French toast with the table. There are a couple of
                    tables outside if you want to bring your furry friend."
                  </h5>
                  <h6>- James inLA</h6>
                </div>
              </div>
              <div className="y-content">
                <div className="content">
                  <Star />
                  <h5>
                    "Absolutely amazing experience! We ordered online and they
                    called us to let us know they didn’t have something in stock
                    and offered a replacement, which was very considerate. The
                    food was fresh and tasted amazing and the portion sizes were
                    bigger than expected! Would absolutely recommend!"
                  </h5>
                  <h6>- Maya Srugo</h6>
                </div>
              </div>
              <div className="y-content">
                <div className="content">
                  <Star />
                  <h5>
                    "Amazing​. I can't say enough about this place. They already
                    have me hooked. The chef himself came out and spoke to our
                    party for a minute. Love when you meet the people who come
                    up with the ideas."
                  </h5>
                  <h6>- Jorge Diaz</h6>
                </div>
              </div>
              <div className="y-content">
                <div className="content">
                  <Star />
                  <h1>Best Breakfast in Newhall</h1>
                  <h5>
                    "Without a doubt they serve the best breakfast In Newhall! A
                    restaurant that really puts effort into making breakfast
                    special is rare. Neighbarista has REALLY big fluffy pancakes
                    with a crisp outside. Nice thick cut perfectly salty bacon
                    and their eggs are done just right . Eggs are easy mess up.
                    I had read some people thought the staff was not welcoming.
                    I have no idea what they were talking about. I was warmly
                    welcomed, my coffee cup was never empty and they had the
                    right balance of checking on me but not making me feel
                    rushed. What more can anyone ask for ? Oh and the Mexican
                    hot cholcolate creamer pairs wonderfully with the coffee.
                    Definitelyy coming back again."
                  </h5>
                  <h6>- John Collins</h6>
                </div>
              </div>
            </Carousel>
          </Slider>
          <div
            data-sal="slide-up"
            data-sal-delay="250"
            data-sal-easing="ease"
            className="insta"
          >
            <a
              href="https://www.instagram.com/neighbaristascv/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../../images/instagram-pic.png"
                placeholder="blurred"
                quality={100}
                alt="Neighbarista Instagram"
              />
            </a>
          </div>
        </RevWrap>
      </MainWrapper>
    </HowBack>
  )
}

const Star = () => (
  <div className="star">
    <i></i>
    <i></i>
    <i></i>
    <i></i>
    <i></i>
  </div>
)
