import styled from "styled-components"

export const NavBar = styled.div`
  transition: 0.3s;

  .scroll {
    display: inherit;

    .scroll-mar {
      margin: 0;
      transition: 0.3s;
      background-color: transparent !important;
      backdrop-filter: inherit;
    }

    .item-content {
      a {
        @media (max-width: 992px) {
          color: var(--white);
        }
      }
    }
  }
`

export const NavWrapper = styled.nav`
  width: 100%;
  position: fixed;
  z-index: 999;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgb(213 206 206 / 55%);
`

export const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4px 0;

  a {
    @media (max-width: 992px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 992px) {
    margin: 0;
    display: block;
  }
`
