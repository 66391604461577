import React from "react"
import styled from "styled-components"

import background from "../../../images/other.png"
import { Gradient } from "../../Header/styles"

const PageWrap = styled.div`
  /* padding: 130px 0 60px 0; */
  position: relative;
  min-height: 250px;
  background-image: ${`url(${background})`};
`
const BackImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`

const Title = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  z-index: 101;

  h1 {
    padding-top: 80px;
    color: var(--white);
    font-size: 35px;
    font-family: "Oswald", sans-serif;
    letter-spacing: 2px;

    @media (max-width: 992px) {
      padding-top: 120px;
    }
  }
`

export const OtherPageWrapper = ({ title }) => {
  return (
    <PageWrap>
      <BackImage>
        <Title>
          <h1 data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
            {title}
          </h1>
        </Title>
        <Gradient />
      </BackImage>
    </PageWrap>
  )
}
