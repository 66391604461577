import styled from "styled-components"
import { Link } from "gatsby"

export const SimpleButton = styled.a`
  color: ${({ color }) => color || "var(--black)"};
  background: ${({ background }) => background || "var(--thaiChili)"};
  padding: ${({ padding }) => padding || "7px 15px"};
  font-size: ${({ fontSize }) => fontSize || "15px"};
  margin: ${({ margin }) => margin || "0"};
  border: ${({ border }) => border || "0"};
  border-radius: var(--buttonBorderRadious);
  cursor: pointer;
  transition: 0.3s;
  font-family: "Oswald", sans-serif;
  height: ${({ height }) => height || "inherit"};

  &:hover {
    background: var(--secondaryColor);
    color: var(--white);
  }

  @media (max-width: 768px) {
    margin: 0px;
  }
`

export const StyledLink = styled(Link)`
  padding: 10px 20px;
  color: var(--white);
  transition: 0.3s;
  font-family: var(--fontSecondary);
  background-color: ${({ backgroundColor }) => backgroundColor || "unset"};

  &:hover {
    background-color: var(--secondaryColor);
  }
`

export const OnClickButton = styled.button`
  cursor: pointer;
  padding: ${({ padding }) => padding || "12px 30px"};
  background: ${({ background }) => background || "var(--primaryColor)"};
  color: ${({ color }) => color || "var(--white)"};
  border-radius: 6px;
  text-align: center;
  outline: 0;
  border: 0;
  font-family: "Lato", sans-serif;

  &:hover {
    transform: translateX("-10px");
  }
`
