import React, { useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"

import {
  SectionHeader,
  MainWrapper,
  Spinner,
  OnClickButton,
  MyMaps,
} from "components"
import {
  ContactWrapper,
  ContactFormContent,
  ContactAndMap,
  Map,
} from "./styles"

export const ContactForm = () => {
  // button click
  const [loading, setLoading] = useState(false)
  // something went wrong
  const [message, setMessage] = useState("")

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  // Form Submit
  const onSubmit = async ({ name, email, message }, e) => {
    setLoading(true)

    await axios
      .post("https://dm2msyt9n5.execute-api.us-east-1.amazonaws.com/final/", {
        name,
        email,
        message,
        destination: "neighbarista@gmail.com",
      })
      .then(res => {
        setMessage("Thank you! We'll be in touch soon!")
        e.target.reset()
      })
      .catch(err => setMessage("Something went wrong!"))

    setLoading(false)
  }

  return (
    <ContactWrapper id="contact">
      <MainWrapper>
        <SectionHeader margin="10px 0 0 0" title="Contact Us" />

        <div
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="cen"
        >
          Send us a message and we will get right back to you.
        </div>
        <ContactAndMap>
          <ContactFormContent onSubmit={handleSubmit(onSubmit)}>
            <div
              data-sal="slide-up"
              data-sal-delay="350"
              data-sal-easing="ease"
              className="up-form"
            >
              <input
                placeholder="Full Name"
                {...register("name", { required: true, maxLength: 30 })}
              />
              {errors.fullName?.type === "required" && "Name is required!"}
              <input
                placeholder="Email"
                {...register("email", { required: true })}
              />
              {errors.email?.type === "required" && "Email is required!"}
            </div>

            <textarea
              data-sal="slide-up"
              data-sal-delay="350"
              data-sal-easing="ease"
              rows="6"
              placeholder="Message"
              {...register("message", { required: true })}
            />
            {errors.message?.type === "required" && "Message is required!"}

            {message ? <span>{message}</span> : ""}

            <div
              data-sal="slide-up"
              data-sal-delay="350"
              data-sal-easing="ease"
              className="submit"
            >
              <OnClickButton disabled={loading} type="submit">
                {loading ? <Spinner /> : "SEND YOUR MESSAGE"}
              </OnClickButton>
            </div>
          </ContactFormContent>
          <Map data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
            <MyMaps />
          </Map>
        </ContactAndMap>
      </MainWrapper>
    </ContactWrapper>
  )
}
