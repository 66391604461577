import styled from "styled-components"

export const ContactWrapper = styled.section`
  position: relative;
  background-color: var(--whiteChalk);

  .cen {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    font-size: 12px;
    color: var(--zoombie);
  }
`

export const ContactAndMap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`

export const Map = styled.div`
  padding: 0 30px;

  div {
    border-radius: 20px;
  }
`

export const ContactFormContent = styled.form`
  padding-top: 20px;
  font-size: var(--fontSizeThree);
  display: block;
  color: var(--black);

  input,
  textarea {
    font-family: "Lato", Sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    border: 1px solid #e2e2e2;
    box-shadow: none;
    outline: none;
    transition: 0.15s;
    padding: 10px 13px 9px 13px;
    margin: 1rem 0;
    border-radius: 6px;
  }

  textarea {
    width: 100%;
  }

  .up-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 00px;
    }
  }

  .submit {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
`
