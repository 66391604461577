import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const FooterWrapper = styled.footer`
  position: relative;
  background-color: var(--whiteChalk);
  padding: 50px 0;
  font-size: 14px;
  text-align: center;
  line-height: 20px;

  > div {
    margin-bottom: 10px;
  }

  > p {
    text-align: center;
  }
`

export const Footer = () => {
  return (
    <FooterWrapper>
      <div data-sal="slide-up" data-sal-delay="250" data-sal-easing="ease">
        Copyright © 2022 All Rights Reserved by{" "}
        <Link to="/">Neighbarista.com</Link> |&nbsp;
        <Link to="/privacy-policy">Privacy Policy</Link> &nbsp;|&nbsp;
        <Link to="/terms-conditions">Terms & Conditions </Link> &nbsp;|&nbsp;
        <Link to="/accessibility">Accessibility </Link>
      </div>

      <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
        Designed by{" "}
        <a href="https://hungryhipposolutions.com">Hungry Hippo Solutions.</a>
      </p>
    </FooterWrapper>
  )
}
