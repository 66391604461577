import styled, { keyframes } from "styled-components"

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid var(--thaiChili);
  border-right: 2px solid var(--thaiChili);
  border-bottom: 2px solid var(--thaiChili);
  border-left: 4px solid var(--white);
  background: transparent;
  width: 18px;
  height: 18px;
  border-radius: 50%;
`
