import styled from "styled-components"

export const HeaderWrapper = styled.header`
  min-height: 700px;
  position: relative;
`

export const BackImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  /* ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(
      top,
      rgb(192 183 183 / 4%) 0%,
      rgb(8 8 8 / 72%) 30%,
      rgb(45 45 45 / 39%) 100%
    );
  } */
`

export const MainContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: left;
  }
`

export const TextAndContent = styled.div`
  text-align: center;

  @media (max-width: 992px) {
    padding: 0 15px 0 15px;
  }

  > h3 {
    margin-bottom: 15px;
    color: var(--white);
    font-size: 35px;
    font-family: "Oswald", sans-serif;
    letter-spacing: 2px;

    @media (max-width: 992px) {
      padding: 0 15px 0 15px;
      font-size: 25px;
      line-height: 30px;
      min-width: 300px;
    }
  }

  > h4 {
    font-style: italic;
    color: var(--secondaryColor);
    font-family: "Oswald", sans-serif;
    font-size: 15px;

    @media (max-width: 992px) {
      font-size: 13px;
    }
  }

  span {
    color: var(--white);
    font-size: 14px;
    padding-left: 7px;
  }

  .social {
    margin-top: 20px;

    > a {
      padding: 0 10px;

      > svg {
        &:hover {
          fill: var(--primaryColor);
          transition: 0.3s;
        }
      }
    }
  }
`
export const Gradient = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  background: -webkit-linear-gradient(
    top,
    rgb(76 73 73 / 80%) 0%,
    rgb(8 8 8 / 70%) 30%,
    rgb(45 45 45 / 69%) 100%
  );
`

export const FirstDiv = styled.div`
  margin: 30px 0 10px 0;
  display: flex;
  justify-content: center;

  @media (max-width: 992px) {
    display: block;
  }

  > div {
    padding: 0 20px;

    @media (max-width: 992px) {
      padding: 5px 0;
    }
  }
`

export const ButtonContent = styled.div`
  margin-top: 20px;
`
