import styled from "styled-components"

export const AboutWrapper = styled.section`
  margin-top: -30px;
  padding-bottom: 150px;
  border-radius: 30px 30px 0 0;
  background-color: var(--whiteChalk);
  position: relative;
  /* background-image: ${({ background }) =>
    `linear-gradient(
            rgba(255, 255, 255, 0.95),
            hsl(0deg 0% 100%)
          ),url(${background})` || "var(--black)"}; */
`

export const AboutContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 40px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  .left {
    padding-top: 30px;

    > div {
      > a {
        padding-right: 15px;
      }
    }
  }

  > div {
    padding: 15px;

    h1 {
      font-family: "Oswald", sans-serif;
      color: var(--primaryColor);
      padding-bottom: 7px;
    }
    span {
      font-size: 14px;
      font-family: "Oswald", sans-serif;
    }
    p {
      padding: 15px 10px 25px 0;
      text-align: left;
      color: var(--zombie);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    ul {
      margin-top: 30px;
    }

    li {
      display: flex;
      margin-top: 20px;

      div {
        border-radius: 50%;
        background-color: var(--thaiChili);
        padding: 7px;
      }

      span {
        margin-left: 20px;
        margin-top: 15px;
        font-family: "Oswald", Sans-serif;
        font-size: 21px;
      }
    }
  }
`
