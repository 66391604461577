import styled from "styled-components"

export const MainWrapper = styled.div`
  margin: 0 auto;
  max-width: 1128px;
  box-sizing: border-box;
  padding: 0 24px 0 24px;
  @media (max-width: 768px) {
    padding: 0 15px 0 15px;
  }
`
