import styled from "styled-components"

export const HowBack = styled.section`
  background: var(--whiteChalk);
  position: relative;
  background-image: ${({ background }) =>
    `linear-gradient(
            rgba(255, 255, 255, 0.95),
            hsl(0deg 0% 100%)
          ),url(${background})` || "var(--black)"};
  margin-top: -30px;
  border-radius: 30px 30px 0 0;
`

export const RevWrap = styled.div`
  padding: 170px 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`

export const Slider = styled.div`
  margin: 70px 0 30px 0;
  display: contents;

  .y-content {
    padding: 0 25px;
    display: flex;
    justify-content: center;
  }

  .content {
    h1 {
      text-align: center;
      font-size: 19px;
      margin-top: 19px;
    }
    h5,
    h6 {
      margin: 20px;
      text-align: center;
    }
    h5 {
      font-size: 16px;
      line-height: 1.3rem;
    }
    h6 {
      font-size: 14px;
      color: var(--walnut);
    }
    .star {
      justify-content: center;
      display: flex;
      i {
        position: relative;
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.9em;
        margin-right: 0.9em;
        margin-bottom: 1.2em;
        border-right: 0.3em solid transparent;
        border-bottom: 0.7em solid var(--secondaryColor);
        border-left: 0.3em solid transparent;
        /* Controlls the size of the stars. */
        font-size: 10px;

        &:before,
        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          top: 0.6em;
          left: -1em;
          border-right: 1em solid transparent;
          border-bottom: 0.7em solid var(--secondaryColor);
          border-left: 1em solid transparent;
          transform: rotate(-35deg);
        }
        &:after {
          transform: rotate(35deg);
        }
      }
    }
  }
  .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px) !important;
  }
  .react-multiple-carousel__arrow::before {
    color: var(--primaryColor);
  }
  .react-multiple-carousel__arrow {
    background: transparent;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px) !important;
  }

  .react-multiple-carousel__arrow:hover {
    background: var(--secondaryColor);
  }
`
