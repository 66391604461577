import React from "react"
import LightboxReact from "lightbox-react"
import "lightbox-react/style.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const LightboxComponent = ({
  images,
  selectedImage,
  handleClose,
  handlePrevRequest,
  handleNextRequest,
}) => {
  const array = []

  images.forEach(image =>
    array.push(
      <GatsbyImage
        className="gallery"
        image={getImage(image.node)}
        height={window.innerHeight - 50}
      />
    )
  )

  return (
    <LightboxReact
      enableZoom={false}
      clickOutsideToClose={true}
      mainSrc={array[selectedImage]}
      nextSrc={array[(selectedImage + 1) % array.length]}
      prevSrc={array[(selectedImage + array.length - 1) % images.length]}
      onCloseRequest={handleClose}
      onMovePrevRequest={handlePrevRequest(selectedImage, array.length)}
      onMoveNextRequest={handleNextRequest(selectedImage, array.length)}
    />
  )
}

export default LightboxComponent
