import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export const AnchorLinkStyled = styled(AnchorLink)`
  color: ${({ color }) => color || "var(--white)"};
  padding: ${({ padding }) => padding || "0 15px"};
  font-size: ${({ fontSize }) => fontSize || "15px"};
  margin: ${({ margin }) => margin || "0"};
  border: ${({ border }) => border || "0"};
  border-radius: var(--borderRadiusOne);
  cursor: pointer;
  transition: 0.3s;
  font-family: ${({ fontFamily }) => fontFamily || "'Oswald', sans-serif"};

  &:hover {
    color: var(--secondaryColor);
  }

  @media (max-width: 768px) {
    margin: 0px;
  }
`
