import React, { useEffect } from "react"

import { MainWrapper } from "components"
import { NavWrapper, NavContent, NavBar } from "./styles"
import { Logo } from "./Logo"
import { Menu } from "./Menu"

export const Nav = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      //Nacbar Color on Scroll
      window.onscroll = () => {
        const scrollNav = document.querySelector(".head")

        const scrollMe = window.scrollY
        if (scrollMe >= 200) {
          scrollNav.classList.add("scroll")
        } else {
          scrollNav.classList.remove("scroll")
        }
      }
    }
  }, [])

  return (
    <NavBar>
      <NavWrapper className="head">
        <MainWrapper>
          <NavContent className="scroll-mar">
            <Logo />
            <Menu />
          </NavContent>
        </MainWrapper>
      </NavWrapper>
    </NavBar>
  )
}
