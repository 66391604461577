import React from "react"
import styled from "styled-components"

import { MainWrapper, SimpleButton } from "components"
import { ButtonContent } from "../Header/styles"

const Image = styled.section`
  margin: 60px 0;
`

const Review = styled.section`
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 60px 0;
`

const Content = styled.div`
  max-width: 600px;

  h1 {
    font-family: "Oswald", sans-serif;
    color: var(--primaryColor);
    padding-bottom: 7px;
  }

  p {
    padding: 15px 10px 25px 0;
    color: var(--zombie);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  h6 {
    margin-bottom: 70px;
  }
`

export const SeoPage = ({ children, name, review }) => (
  <MainWrapper>
    <Image data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease">
      {children}
    </Image>
    <Review>
      <Content>
        <h1 data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease">
          Reviews
        </h1>
        <p data-sal="slide-up" data-sal-delay="250" data-sal-easing="ease">
          "{review}"
        </p>
        <h6 data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
          {name}
        </h6>

        <ButtonContent
          data-sal="slide-up"
          data-sal-delay="350"
          data-sal-easing="ease"
        >
          <SimpleButton
            background="var(--primaryColor)"
            color="white"
            padding="15px 30px"
            margin="0"
            fontSize="18px"
            href="https://direct.chownow.com/order/27923/locations"
          >
            Order Online
          </SimpleButton>
        </ButtonContent>
      </Content>
    </Review>
  </MainWrapper>
)
