import React from "react"
import styled from "styled-components"

const CardWrapper = styled.div`
  text-align: center;
  color: var(--zombie);

  .title {
    display: flex;
    justify-content: center;

    > h2 {
      margin: 40px 0 30px 0;
      padding-bottom: 10px;
      border-bottom: 3px solid var(--secondaryColor);
      border-radius: 5px;
      display: table-caption;
      white-space: nowrap;
      color: var(--black);
      font-family: "Oswald", sans-serif;
      text-align: center;

      /* &::before {
      content: "";
      display: block;
      width: 120px;
      height: 2px;
      background: #000;
      right: 0;
      top: 50%;
      position: absolute;
    } */
    }
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  p {
    margin: 20px 0;
    font-weight: 400;
    line-height: 20px;
  }
`

const Card = ({ title, children }) => {
  return (
    <CardWrapper>
      <div className="title">
        <h2>{title}</h2>
      </div>

      {children}
    </CardWrapper>
  )
}

export { Card }
