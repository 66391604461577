import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  :root {
    /* ------------------------- color ------------------------- */
    --primaryColor: #317035;
    --secondaryColor: #ffc233;
    --walnut: #743c1b;
    --zombie: #4f4f4f;
    --whiteChalk: #F6F4F1;
    --white: #fff;
    --black: #000;
    /* ------------------------- box shadow & border ------------------------- */
    //navbar
    --navbarBoxShadow: 0px 2px 12px #0000001D;
    //button
    --buttonBorderRadious: 10px;
    /*  homepage  */
    //blog section
    --blogItemBoxShadow: 0px 2px 16px #394E5D36;
    --blogImageBorderRadious: 4px;
    /* ------------------------- font size ------------------------- */
    /*  common font size  */
    --commonTitle: 20px;
    //navbar
    --navitem: 15px;
    //button
    --buttonText: 14px;
    //copyright
    --copyrightText: 14px;
    /*  homepage  */
    //hero
    --heroDetails: 22px;
    --heroText: 26px;
    //section common
    --sectionTitle: 32px;
    //lorem ipsum section
    --loremDetails: 18px;
    //blog section
    --blogSectionDetails: 16px;
     /* ------------------------- font weight ------------------------- */
    --normal: 400;
    --semiBold: 400;
    --bold: 500;
     /* ------------------------- transition ------------------------- */
     --defaultTransition: 0.3s;
     /* ------------------------- navbar height ------------------------- */
     --navbarHeight: 92px;
  }
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body{
    font-family: 'Lato', sans-serif;
    color: var(--black);
    font-weight: var(--normal);
    line-height: 1;
  }
    ol, ul {
    list-style: none;
  }
  a {
    text-decoration: none;
    display: inline-block;
  }
  .show-image {
    overflow: hidden;
    cursor: zoom-in;
    border-radius:20px;
  }
  .show-image img {
    transition: all 1.5s ease!important;
    border-radius:20px;

  }
 .gallery {
  width: auto;
    margin: 50px auto 0px;
    display: table;
 }
  .show-image:hover img {
    transform: scale(1.2);
    border-radius:20px;
  }
`
