import React from "react"
import styled from "styled-components"

const Header = styled.div`
  padding: 50px 0 40px 0;
  text-align: center;

  > h1 {
    font-family: "Oswald", sans-serif;
    font-size: 40px;
    font-weight: var(--bold);
    letter-spacing: 2px;
    margin: ${({ margin }) => margin || "10px 0 20px 0"};
    color: ${({ color }) => color || "var(--walnut)"};
  }
`
export const SectionHeader = ({ title, color, margin }) => {
  return (
    <Header
      margin={margin}
      color={color}
      data-sal="slide-up"
      data-sal-delay="250"
      data-sal-easing="ease"
    >
      <h1> {title}</h1>
    </Header>
  )
}
