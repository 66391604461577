import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ThumbGrid = ({ images, handleOpen }) => {
  return (
    <>
      {images.map((image, i) => (
        <div key={i} className="gallery-col">
          <div onClick={handleOpen(i)} className="previewButton show-image">
            <GatsbyImage image={getImage(image.node)} alt="Gallery" />
          </div>
        </div>
      ))}
    </>
  )
}

export { ThumbGrid }
