import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { SimpleButton, AnchorLinkStyled } from "components"

const MenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0 0 50px;

  @media (max-width: 992px) {
    margin: 8px 0 0 0;
    order: 1;

    > a > svg {
      margin-top: -4px;
      padding-top: 2px;
    }
  }
`

export const Hamburger = styled.div`
  background-color: var(--black);
  width: 17px;
  height: 2px;
  transition: all 0.3s linear;
  position: relative;
  align-self: center;
  transform: ${props =>
    props.open ? "rotate(45deg) translate(-3px,-3px)" : "inherit"};
  ::before {
    width: 17px;
    height: 2px;
    background-color: var(--black);
    content: "";
    position: absolute;
    transition: var(--defaultTransition);
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-8px, 0px)" : "rotate(0deg)"};
    top: -8px;
  }
`

export const Toggle = styled.div`
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: none;
  background: var(--cornFlowerBlue);
  border-radius: 50%;

  @media (max-width: 992px) {
    display: block;
    padding: 20px 6px 5px 10px;
    ::focus {
      background: transparent;
    }
  }
`

const StyledLink = styled(Link)`
  padding: 10px 20px;
  color: var(--white);
  font-family: "Oswald", sans-serif;
  transition: 0.3s;

  &:hover {
    color: var(--secondaryColor);
  }
`

const MenuItem = styled.div`
  display: flex;
  list-style: none;
  transition: all 0.3s ease 0s;
  font-size: 14px;

  .item-content {
    display: flex;
    justify-content: center;

    @media (max-width: 992px) {
      padding: 30px;
      display: block;
    }
  }

  @media (max-width: 992px) {
    display: ${({ open }) => (open ? "block" : "none")};
    position: absolute;
    background: var(--black);
    width: calc(100% - 30px);
    border-radius: 30px;
    top: 130px;
    left: 0;
    padding: 28px;
    margin: 15px;
  }
`

// const NavItem = ({ href, name }) => {
//   return (
//     <Zoom duration={1000}>
//       <li>
//         <Link href={href} passHref>
//           <StyledLink>{name}</StyledLink>
//         </Link>
//       </li>
//     </Zoom>
//   );
// };

export const Menu = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <MenuWrapper>
      <Toggle onClick={() => setNavbarOpen(!navbarOpen)}>
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      <MenuItem open={navbarOpen}>
        <ul className="item-content">
          <li>
            <StyledLink to="/#about">About Us</StyledLink>
          </li>
          <li>
            <StyledLink to="/menu">Our Menu</StyledLink>
          </li>
          <li>
            <StyledLink to="/locations">Locations</StyledLink>
          </li>
          <li>
            <AnchorLinkStyled padding="10px 20px" to="/gallery">
              Gallery
            </AnchorLinkStyled>
          </li>
          <li>
            <AnchorLinkStyled padding="10px 20px" to="/#contact">
              Contact Us
            </AnchorLinkStyled>
          </li>
          <SimpleButton
            height="35px"
            color="var(--white)"
            background="var(--primaryColor)"
            padding="9px 30px"
            href="https://direct.chownow.com/order/27923/locations"
          >
            Order Online
          </SimpleButton>
        </ul>
      </MenuItem>
    </MenuWrapper>
  )
}
