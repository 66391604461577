import React, { Fragment } from "react"
import { GlobalStyles } from "components"
import "react-multi-carousel/lib/styles.css"

//global styles for whole website
export const wrapPageElement = ({ element }) => (
  <Fragment>
    <GlobalStyles />
    {element}
  </Fragment>
)
