import styled from "styled-components"

export const FeaturesWrapper = styled.section`
  margin-top: -30px;
  border-radius: 30px 30px 0 0;
  position: relative;
  background-image: ${({ background }) =>
    `linear-gradient( rgb(255 255 255 / 0%), hsl(0deg 0% 100% / 53%) ),url(${background})` ||
    "var(--black)"};
`

export const MainContent = styled.div`
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  border-radius: 16px;
  padding: 0 24px 50px 24px;
`

export const CardWrapper = styled.div`
  display: grid;
  margin: 60px 0 30px 0;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px) !important;
  }
  .react-multiple-carousel__arrow::before {
    color: var(--thaiChili);
  }
  .react-multiple-carousel__arrow {
    background: transparent;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px) !important;
  }

  .react-multiple-carousel__arrow:hover {
    background: #cf00166e;
  }
`
